@font-face {
  font-family: defaultFont;
  src: url(assets/kalam/Kalam-Regular.ttf);
}

.App {
  font-family: defaultFont;
  text-align: center;
  position: relative;
}

.App::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.75;
  background-image: url("./assets/background-woods-2.jpeg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  filter: grayscale(70%) brightness(95%);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.AppHeader {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.ImageRow {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ClickHereText {
  display: flex;
  flex-direction: column;
  font-size: 1vw;
  transform: translate(-1.25vw, -1.5vw) rotate(5deg);
}

.ClickHereText-arrow {
  height: 1.5vw;
  width: 1.5vw;
  background: url(assets/right-drawn-arrow.png);
  background-size: 1.5vw 1.5vw;
  transform: translate(1.25vw, -1vw) rotate(-20deg);
}

.UnlockDateText {
  display: flex;
  flex-direction: column;
  font-size: 1vw;
  transform: translate(-3vw, 6.5vw) rotate(30deg);
}

.UnlockDateText-arrow {
  height: 1.5vw;
  width: 1.5vw;
  background: url(assets/right-drawn-arrow.png);
  background-size: 1.5vw 1.5vw;
  transform: translate(-0.25vw, -1vw) scale(-1, 1) rotate(10deg);
  z-index: 10;
}
