.Image {
  background: hsl(50, 43%, 97%);
  display: flex;
  flex-direction: column;
  align-items: center;
  object-fit: none;
}

.ImageEnabled {
  cursor: pointer;
}

.ImageEnabled:hover {
  background: hsl(47, 19%, 91%);
  box-shadow: 4px 4px 12px #727171;
}

.InnerImage {
  width: 84%;
  margin-top: 8%;
  height: 72%;
  background: #000; /* if no photo is provided, we default to black */
  background-size: 100% 100%;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.InnerImageEnabled {
  opacity: 1;
}

.LockImage {
  /* position: fixed; */
  background: url("../assets/lock.png");
  height: 50%;
  width: 50%;
}

.Text {
  color: black;
  font-size: 1.5vw;
  height: 22%; /* fix this */
  display: flex;
  align-items: center;
}
