.OutdoorsMessage {
  margin-left: 2vw;
  text-align: left;
  font-size: 1.25vw;
}

.Store {
  font-size: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* to have equal columns */
  flex: 1;
  margin: 0 1%;
}

.Store-errorMessage {
  font-size: 1vw;
  color: red;
}

.StoreContent {
  display: flex;
  height: 100%;
  align-items: center;
}

.OutdoorsStoreRow {
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 12vw;
  margin-right: 12vw;
}

.Store-image {
  margin-top: 2vw;
  width: 12vw;
  height: 12vw;
  background-size: 12vw 12vw;
}

.Store-revealButton {
  margin-top: 0.25vw;
  background: #a7c7e7;
  font-size: 1vw;
  padding: 0.4vw;
  border-radius: 0.3vw;
  cursor: pointer;
  box-shadow: 0.25vw 0.25vw 0.25vw grey;
}

.Store-revealButton:active {
  background: #93a7bb;
  transform: translate(0.25vw, 0.25vw);
  box-shadow: none;
}

.Store-giftSummary {
  margin: 1.5vw;
  font-size: 1vw;
  text-align: left;
}

.StoreForm {
  margin-top: 1.5vw;
  text-align: left;
  font-size: 1vw;
  display: flex;
  flex-direction: column;
}

.StoreForm-emailPasscode {
  display: flex;
}

.StoreForm-input {
  height: 1vw;
  width: 11vw;
  font-size: 1vw;
}

.StoreForm-submit {
  background: #c1e1c1;
  margin-left: 0.5vw;
  text-decoration: none;
  border: none;
  border-radius: 0.3vw;
  width: 1vw;
  height: 0.85vw; /* odd height */
  padding: 0.25vw;
  cursor: pointer;
  box-shadow: 0.1vw 0.1vw 0.1vw grey;
}

.StoreForm-submit:active {
  background: #93a7bb;
  transform: translate(0.1vw, 0.1vw);
  box-shadow: none;
}
