.Background {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 5; /* Specify a stack order in case you're using a different order for other elements */
  display: flex;
  align-items: center;
  justify-content: center;
}

.Card {
  position: relative;
  background: hsl(50, 43%, 97%);
  width: 64vw;
  height: 36vw;
}

.CloseButton {
  height: 1vw;
  width: 1vw;
  background-image: url("../assets/close.png");
  background-size: 1vw 1vw;
  position: absolute;
  top: 1.5vw;
  right: 1.5vw;
  cursor: pointer;
}
